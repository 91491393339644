import { GET_MODULE_LEVEL_ACCESS } from "config/api";
import axiosInstance from "Utils/axios";
import axiosPlatformInstance from "Utils/axiosPlatform";
import {
  DATAPLATFORM_DATA_SOURCE,
  DATAPLATFORM_DATA_SOURCE_MAPPING,
  DATAPLATFORM_DATA_TRANSFER_GET_DYNAMIC_FIELDS,
  DATAPLATFORM_DATA_TRANSFER_GET_JOB_CONFIG_DYNAMIC_FIELDS,
  DATAPLATFORM_DATA_TRANSFER_GET_JOB_LIST,
  DATAPLATFORM_DATA_TRANSFER_GET_TOTAL_JOB_COUNT,
  DATAPLATFORM_DATA_TRANSFER_SUBMIT_ENDPOINT,
  DATAPLATFORM_DATA_SCHEDULE_SUBMIT_ENDPOINT,
  DATAPLATFORM_DATA_SCHEDULE_VALIDATE_ENDPOINT,
  DATAPLATFORM_GET_SAMPLE_DATA,
  DATAPLATFORM_GENERIC_SCHEMA_MAPPING,
  DATAPLATFORM_TEST_CONNECTOR_CONFIG,
  DATA_PLATFORM_DAG_IDS,
  DATA_PLATFORM_DAG_INFO,
  DATA_PLATFORM_TIME_TRACKER_DAGS_LIST,
  DATA_PLATFORM_COST_DAG_IDS,
  DATA_PLATFORM_DAG_COST_INFO,
  DATA_PLATFORM_MODULES_CONFIG,
  DATA_PLATFORM_PAUSE_PIPELINE,
  DATA_PLATFORM_RESUME_PIPELINE,
  DATA_PLATFORM_CLEAR_TASK,
  DATA_PLATFORM_START_PIPELINE,
  DATA_PLATFORM_UPDATE_SUBMODULE_STATUS,
  DATA_PLATFORM_AIRFLOW_USER_INFO,
  DATA_PLATFORM_SAMPLE_INGESTION_DATA,
  DATAPLATFORM_INGESTION_GENERIC_MASTER_MAPPING,
  DATA_PLATFORM_SAMPLE_INGESTION_REPORT,

  DATA_PLATFORM_CREATE_AIRFLOW_USER,
  DATA_PLATFORM_UPDATE_AIRFLOW_USER,
  DATAPLATFORM_SOURCING_MAPPING_UPLOAD_CONFIG,
  DATAPLATFORM_GENERIC_MASTER_MAPPING_UPLOAD_CONFIG,
  DATAPLATFORM_GENERIC_SCHEMA_MAPPING_UPLOAD_CONFIG,
  DATAPLATFORM_SOURCING_MAPPING_SAVE_CONFIG,
  DATAPLATFORM_SOURCING_MASTER_MAPPING_SAVE_CONFIG,
  DATAPLATFORM_GENERIC_SCHEMA_MAPPING_SAVE_CONFIG,
  DATAPLATFORM_DERIVED_TABLE_UPLOAD_CONFIG,
  DATAPLATFORM_DERIVED_TABLE_SAVE_CONFIG,
  DATAPLATFORM_QC_MODULE_RULE_MASTER_UPLOAD_CONFIG,
  DATAPLATFORM_QC_MODULE_RULE_MASTER_SAVE_CONFIG,
  DATAPLATFORM_QC_MODULE_KPI_MASTER_UPLOAD_CONFIG,
  DATAPLATFORM_QC_MODULE_KPI_MASTER_SAVE_CONFIG,
  DATAPLATFORM_DERIVED_TABLE_MAPPING,
  DATA_PLATFORM_SUBMIT_QUERY,
  DATA_PLATFORM_VIEW_CONTENT,
  DATA_PLATFORM_VIEW_ALL_PRS,
  DATA_PLATFORM_GET_APPROVERS_LIST,
  DATA_PLATFORM_APPROVE_PR,
  DATA_PLATFORM_DECLINE_PR,
  DATA_PLATFORM_VALIDATE_PR,
  DATA_PLATFORM_ADD_COMMENT_REVIEW_WORKFLOW,
  DATA_PLATFORM_VIEW_ALL_COMMENTS_REVIEW_WORKFLOW,
  DATA_PLATFORM_REPLY_TO_COMMENT_REVIEW_WORKFLOW,
  DATA_PLATFORM_MERGE_PR,
  DATAPLATFORM_TRIGGERS_UPLOAD_CONFIG,
  DATAPLATFORM_TRIGGER_SAVE_CONFIG,
  DATA_PLATFORM_GET_SCHEDULED_TASK_LIST,
  DATA_PLATFORM_DELETE_SCHEDULED_TASK_BY_NAME,
  DATA_PLATFORM_GET_ALL_TRANSFER_JOBS,
  DATA_PLATFORM_MERGE_CONFLICT_PR,
  DATA_PLATFORM_SAVE_SAMPLE_SELECTION,
  DATA_PLATFORM_GET_SAMPLE_SELECTION_VIEW_CONFIGS,
  DATAPLATFORM_DATA_TRANSFER_GET_JOB_EDIT_CONFIG_DYNAMIC_FIELDS,
  DATAPLATFORM_DATA_SCHEDULE_GET_TARGET_SYSTEM,
  DATAPLATFORM_DATA_SCHEDULE_GET_SOURCE_SYSTEM,
  DATA_PLATFORM_GET_ALL_API_INGESTION_FILES,
  DATA_PLATFORM_REUPLOAD_API_INGESTION_FILES,
  DATA_PLATFORM_REUPLOAD_API_INGESTION_FILE_DETAILS_FILTER
} from "../constants-dataIngestion/apiConstants";

//module access
export const getModuleLevelAccess = (app, module) => () => {
  const queryParam = {
    app,
    module,
  };
  return axiosInstance({
    url: `${GET_MODULE_LEVEL_ACCESS}`,
    params: queryParam,
    method: "GET",
  });
};

// Data Transfer
export const saveDataTransferFormDetails = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_TRANSFER_SUBMIT_ENDPOINT}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};
//Data Scheduling
export const saveDataScheduleFormDetails = (reqBody, job_name, edit) => async () => {
  const URL = edit ? `${DATAPLATFORM_DATA_SCHEDULE_SUBMIT_ENDPOINT}/${job_name}` : DATAPLATFORM_DATA_SCHEDULE_SUBMIT_ENDPOINT
  const { data } = await axiosPlatformInstance({
    url: URL,
    method: edit ? "PUT" : "POST",
    data: reqBody,
  });
  return data;
};

export const validateDataScheduleFormDetails = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_SCHEDULE_VALIDATE_ENDPOINT}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const dataScheduleGetTargetSystem = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_SCHEDULE_GET_TARGET_SYSTEM}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const dataScheduleGetSourceSystem = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_SCHEDULE_GET_SOURCE_SYSTEM}`,
    method: "GET"
  });
  return data;
};

export const getDataTransferFormDynamicFields = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_TRANSFER_GET_DYNAMIC_FIELDS}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const getDataTransferFormJobConfigDynamicFields = (reqBody, edit) => async () => {
  const URL = edit ? DATAPLATFORM_DATA_TRANSFER_GET_JOB_EDIT_CONFIG_DYNAMIC_FIELDS : DATAPLATFORM_DATA_TRANSFER_GET_JOB_CONFIG_DYNAMIC_FIELDS
  const { data } = await axiosPlatformInstance({
    url: URL,
    method: "POST",
    data: reqBody
  });
  return data;
};

export const getTotalJobCount = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_TRANSFER_GET_TOTAL_JOB_COUNT}`,
    method: "GET"
  });
  return data;
};

export const getJobList = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_TRANSFER_GET_JOB_LIST}`,
    method: "POST",
    data: reqBody,
  });
  return data;
}


//data-sources connector
export const getAllConnectors = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_SOURCE}`,
    method: "GET",
  });
  return data;
}

export const testConnector = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_TEST_CONNECTOR_CONFIG}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const addConnectorTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_SOURCE}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const getConnectorTableConfigById = (
  Id
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_SOURCE}/${Id}`,
    method: "GET"
  });
  return data;
};

export const updateConnectorById = (
  Id, reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_SOURCE}/${Id}`,
    method: "PUT",
    data: reqBody,
  });
  return data;
};
export const deleteConnectorConfigById = (Id, postBody) => async () => {
  return axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_SOURCE}/${Id}`,
    method: "DELETE",
    data: postBody
  });
};

//sourcing mapping
export const createMappingTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_SOURCE_MAPPING}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const uploadMappingTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_SOURCING_MAPPING_UPLOAD_CONFIG}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

export const saveUploadedMappingTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_SOURCING_MAPPING_SAVE_CONFIG}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

export const deleteMappingConfig = (postBody) => async () => {
  return axiosPlatformInstance({
    url: DATAPLATFORM_DATA_SOURCE_MAPPING,
    method: "DELETE",
    data: postBody
  });
};

//sample data view
export const getSampleDataView = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_GET_SAMPLE_DATA}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

//generic master mapping table
export const createGenericMasterMappingTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_INGESTION_GENERIC_MASTER_MAPPING}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const deleteMasterMappingConfig = (postBody) => async () => {
  return axiosPlatformInstance({
    url: `${DATAPLATFORM_INGESTION_GENERIC_MASTER_MAPPING}`,
    method: "DELETE",
    data: postBody
  });
};

export const editMasterMappingConfig = (postBody) => async () => {
  return axiosPlatformInstance({
    url: `${DATAPLATFORM_INGESTION_GENERIC_MASTER_MAPPING}`,
    method: "PUT",
    data: postBody
  });
};

export const uploadMasterMappingTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_GENERIC_MASTER_MAPPING_UPLOAD_CONFIG}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

export const saveUploadedMasterMappingTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_SOURCING_MASTER_MAPPING_SAVE_CONFIG}`,
    method: "POST",
    data: reqBody
  });
  return data;
};


//generic schema mapping
export const createGenericSchemaMappingTableConfig = (
  reqBody, table_name
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_GENERIC_SCHEMA_MAPPING}/${table_name}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const deleteSchemaMappingConfig = (postBody, table_name) => async () => {
  return axiosPlatformInstance({
    url: `${DATAPLATFORM_GENERIC_SCHEMA_MAPPING}/${table_name}`,
    method: "DELETE",
    data: postBody
  });
};

export const editSchemaMappingConfig = (postBody, table_name) => async () => {
  return axiosPlatformInstance({
    url: `${DATAPLATFORM_GENERIC_SCHEMA_MAPPING}/${table_name}`,
    method: "PUT",
    data: postBody
  });
};

export const uploadSchemaMappingTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_GENERIC_SCHEMA_MAPPING_UPLOAD_CONFIG}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

export const saveSchemaMappingTableConfig = (
  reqBody,
  table_name
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_GENERIC_SCHEMA_MAPPING_SAVE_CONFIG}/${table_name}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

//derived table
export const uploadDerivedTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DERIVED_TABLE_UPLOAD_CONFIG}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

export const saveDerivedTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DERIVED_TABLE_SAVE_CONFIG}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

export const deleteDerivedTableConfig = (postBody) => async () => {
  return axiosPlatformInstance({
    url: `${DATAPLATFORM_DERIVED_TABLE_MAPPING}`,
    method: "DELETE",
    data: postBody
  });
};

//reports

export const getDagsList = (
  module
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_TIME_TRACKER_DAGS_LIST}/${module}`,
    method: "GET"
  });
  return data;
};

export const getDagIds = (
  start, end
) => async () => {
  let URL = !(start && end) ? `${DATA_PLATFORM_DAG_IDS}` : `${DATA_PLATFORM_DAG_IDS}?start_date_gte=${start}&start_date_lte=${end}`;
  const { data } = await axiosPlatformInstance({
    url: URL,
    method: "GET"
  });
  return data;
};

export const getDagTaskInfo = (
  module, dag_run_id, dag_id
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_DAG_INFO}/${module}/${dag_id}/${dag_run_id}`,
    method: "GET"
  });
  return data;
};

export const getDagCostIds = (
  start, end
) => async () => {
  let URL = !(start && end) ? `${DATA_PLATFORM_COST_DAG_IDS}` : `${DATA_PLATFORM_COST_DAG_IDS}?start_date=${start}&end_date=${end}`;
  const { data } = await axiosPlatformInstance({
    url: URL,
    method: "GET",
  });
  return data;
};

export const getModulesInfo = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_MODULES_CONFIG}`,
    method: "GET",
  });
  return data;
};

export const getSampleIngestionCurrentData = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_SAMPLE_INGESTION_DATA}`,
    method: "GET",
  });
  return data;
};

export const getDagTaskCostInfo = (
  module, dag_run_id, dag_id
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_DAG_COST_INFO}/${module}/${dag_id}/${dag_run_id}`,
    method: "GET"
  });
  return data;
};
export const pausePipeline = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_PAUSE_PIPELINE}`,
    method: "GET",
  });
  return data;
};

export const resumePipeline = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_RESUME_PIPELINE}`,
    method: "GET",
  });
  return data;
};

export const clearTask = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_CLEAR_TASK}`,
    method: "GET",
  });
  return data;
};

export const startPipeline = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_START_PIPELINE}`,
    method: "POST",
    data: {
      "ingestion_type": "non-periodic"
    }
  });
  return data;
};

export const updateSubmoduleStatus = (id) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_UPDATE_SUBMODULE_STATUS}/${id}`,
    method: "GET",
  });
  return data;
};


export const viewSampleIngestionReport = (id) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_SAMPLE_INGESTION_REPORT}`,
    method: "GET",
  });
  return data;
};

export const getAirflowUserInfo = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_AIRFLOW_USER_INFO}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};
//rule master

export const uploadRuleMasterTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_QC_MODULE_RULE_MASTER_UPLOAD_CONFIG}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

export const createAirflowUser = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_CREATE_AIRFLOW_USER}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const saveRuleMasterTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_QC_MODULE_RULE_MASTER_SAVE_CONFIG}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

export const updateAirflowUser = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_UPDATE_AIRFLOW_USER}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};
//kpi sets

export const uploadKpiMasterTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_QC_MODULE_KPI_MASTER_UPLOAD_CONFIG}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

export const saveKpiMasterTableConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_QC_MODULE_KPI_MASTER_SAVE_CONFIG}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

//Triggers
export const saveTriggersConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_TRIGGER_SAVE_CONFIG}`,
    method: "POST",
    data: reqBody
  });
  return data;
}

export const uploadTriggersConfig = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_TRIGGERS_UPLOAD_CONFIG}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

// Review Workflow (Queries)

export const submitQuery = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_SUBMIT_QUERY}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

export const viewContentDiff = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_VIEW_CONTENT}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

export const viewAllPRs = (module) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_VIEW_ALL_PRS}/${module}`,
    method: "GET"
  });
  return data;
};

export const getApproversList = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_GET_APPROVERS_LIST}`,
    method: "GET"
  });
  return data;
};

export const approvePR = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_APPROVE_PR}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

export const declinePR = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_DECLINE_PR}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

export const validateQuery = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_VALIDATE_PR}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

export const addCommentReviewWorkflow = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_ADD_COMMENT_REVIEW_WORKFLOW}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

export const replyToCommentReviewWorkflow = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_REPLY_TO_COMMENT_REVIEW_WORKFLOW}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

export const viewAllCommentReviewWorkflow = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_VIEW_ALL_COMMENTS_REVIEW_WORKFLOW}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const mergeReviewWorkflow = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_MERGE_PR}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};


export const mergeConflictReviewWorkflow = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_MERGE_CONFLICT_PR}`,
    method: "POST",
    data: reqBody
  });
  return data;
};

// sample selection

export const getSampleSelectionConfigs = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_GET_SAMPLE_SELECTION_VIEW_CONFIGS}`,
    method: "GET"
  });
  return data;
};

export const saveSampleSelectionData = (
  reqBody
) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_SAVE_SAMPLE_SELECTION}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

//Scheduling Utility

export const getScheduledTaskList = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_GET_SCHEDULED_TASK_LIST}`,
    method: "GET"
  });
  return data;
};

export const getAllTransferJobs = (scheduledJobName) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_GET_ALL_TRANSFER_JOBS}/${scheduledJobName}`,
    method: "GET"
  });
  return data;
};


export const deleteScheduledTaskByName = (scheduledJobName) => async () => {
  return axiosPlatformInstance({
    url: `${DATA_PLATFORM_DELETE_SCHEDULED_TASK_BY_NAME}/${scheduledJobName}`,
    method: "DELETE"
  });
};

export const saveDataScheduleFormEditDetails = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_SCHEDULE_SUBMIT_ENDPOINT}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const getAllApiIngestionFilesInfo = () => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATA_PLATFORM_GET_ALL_API_INGESTION_FILES}`,
    method: "GET"
  });
  return data;
};

export const reuploadAPiIngestionFile = () => async (job_id) => {
  return axiosPlatformInstance({
    url: `${DATA_PLATFORM_REUPLOAD_API_INGESTION_FILES}/${job_id}`,
    method: "POST"
  });
};

export const filterAPiIngestionFile = () => async (payload) => {
  return axiosPlatformInstance({
    url: `${DATA_PLATFORM_REUPLOAD_API_INGESTION_FILE_DETAILS_FILTER}`,
    method: "POST",
    data: payload
  });
};
